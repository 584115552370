<template>
  <div class="com-name">
    <h1>{{ name || '' }}</h1>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  name: {
    type: String,
    default: '控件',
  },
})
</script>

<style lang='less' scoped>
.com-name {
  text-align: center;
  h1 {
    color: #0A7BFF;
    padding: 24px 0 16px;
    border-bottom: 1px solid #0A7BFF;
    font-size: 16px;
    font-weight: 500;
     height: 66px;

  }
}
</style>